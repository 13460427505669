import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  const [isOpen, setIsOpen] = useState();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header className="w-full grid grid-cols-3 bg-transparent z-10 py-6 px-4 absolute top-0">
        <div className=""></div>
        <div className="flex justify-center">
          <div className="hidden md:flex rounded-lg bg-[#dcdcdc9d] p-3 ">
            <input
              type="text"
              placeholder="Search..."
              className="bg-transparent text-gray-600 placeholder-gray-800 border-0 mr-1"
            />
            <div className="h-full w-[1px] bg-gray-800"></div>
            <span className="px-3">
              <i className="ri-search-line"></i>
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <span
            className="text-3xl text-gray-100 mt-1 cursor-pointer"
            onClick={toggleMenu}
          >
            {!isOpen && <i className="ri-menu-line"></i>}
          </span>
        </div>
      </header>
      <div
        className={`fixed top-0 left-0 h-screen w-full bg-[#00000089] text-white transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-500 z-40`}
      >
        <div className="flex justify-end px-4 py-6">
          <span
            className="text-4xl text-white mt-1 cursor-pointer"
            onClick={toggleMenu}
          >
            <i className="ri-close-circle-line"></i>
          </span>
        </div>
        <ul className="flex flex-col items-center w-full h-full">
          <Link to="/">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              Home
            </li>
          </Link>
          <Link to="/about">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              About Us
            </li>
          </Link>
          <Link to="/whatwedo">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              What we do
            </li>
          </Link>
          <Link to="/howwedoit">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              How we do it
            </li>
          </Link>
          <Link to="/ourblog">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              Our Blog
            </li>
          </Link>
          <Link to="/contact">
            <li className="text-3xl uppercase tracking-wide py-3 cursor-pointer duration-500 ease-in-out hover:underline hover:underline-offset-[12px]">
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}
