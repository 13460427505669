import React from "react";

export default function PageTitle({ title, bg }) {
  return (
    <>
      <section
        className="w-full h-[200px] md:h-[300px] lg:h-[400px] bg-cover bg-center"
        style={bg ? { backgroundImage: `url(${bg})` } : {}}
      ></section>
      <section>
        <h1 className="text-center py-12 text-4xl md:text-7xl tracking-wide">
          {title}
        </h1>
      </section>
    </>
  );
}
