import React from "react";
import Nav from "../Ui/Nav";
import Footer from "../Ui/Footer";

import logo from "../assets/logo.png";
import PageTitle from "../Ui/PageTitle";

export default function About() {
  return (
    <>
      <Nav />
      <PageTitle
        title="About"
        bg="https://4kwallpapers.com/images/walls/thumbs_3t/2881.jpg"
      />
      <section className="px-5 lg:px-32">
        <h3 className="text-center text-xl md:text-3xl">
          Archisium is a global firm of architects, designers, strategists, and
          specialists. We focus exclusively on environments through the lens of
          interior architecture—a radical idea. We are highly connected agents
          of change, committed to creativity, innovation, growth, & our
          communities.
        </h3>
        <h4 className="my-5 text-center text-sm md:text-lg text-gray-500">
          Through innovation, research, and building on smart, highly creative
          solutions that embody our clients’ brands, we advance the field. As an
          employee-owned firm, our diverse and purpose-driven individuals bring
          new ideas to life, while challenging the status quo. We’re inspired
          working alongside our clients to simplify complex challenges and
          design human-centric environments where people thrive. Through our
          craft we strive to share our passion with our clients.
        </h4>
        <div className="flex justify-center w-full py-5">
          <img className="" src={logo} alt="Archisium Logo" />
        </div>
        <ul className="flex justify-evenly my-8">
          <li className="text-lg cursor-pointer hover:text-gray-600 duration-300 ease-in">
            <span>
              <i class="ri-arrow-right-circle-line"></i>
            </span>{" "}
            Reach
          </li>
          <li className="text-lg cursor-pointer hover:text-gray-600 duration-300 ease-in">
            <span>
              <i class="ri-arrow-right-circle-line"></i>
            </span>{" "}
            Diversity
          </li>
        </ul>
      </section>
      <section className="w-full h-[3px] bg-yellow-300 my-5"></section>
      <Footer />
    </>
  );
}
