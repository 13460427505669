import React from "react";
import Nav from "../Ui/Nav";
import Footer from "../Ui/Footer";
import PageTitle from "../Ui/PageTitle";
import MapEmbed from "../Ui/MapEmbed";

export default function Contact() {
  const handlerSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Nav />
      <PageTitle
        title="Contact Us"
        bg="https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />
      <section className="px-5 lg:px-32">
        <form
          className="p-5 lg:p-16 grid grid-cols-1 lg:grid-cols-2 border-2 border-black"
          onSubmit={handlerSubmit}
        >
          <div className="flex flex-col items-center gap-5 ">
            <input
              type="text"
              placeholder="Full Name"
              className="py-1 px-2 border-b-2 border-black text-gray-800 placeholder:text-gray-500 text-xl w-full"
            />
            <input
              type="text"
              placeholder="E-Mail"
              className="py-1 px-2 border-b-2 border-black text-gray-800 placeholder:text-gray-500 text-xl w-full"
            />
            <textarea
              placeholder="Message"
              className="py-1 px-2 border-b-2 border-black text-gray-800 placeholder:text-gray-500 text-xl w-full"
            />
            <button className="text-xl py-1 px-3 text-gray-800 border-2 border-black  hover:bg-yellow-300 duration-200 ease-in self-end">
              Submit
            </button>
          </div>
          <div className="flex flex-col items-center  gap-2 mt-10 lg:mt-0">
            <h3 className="text-3xl text-gray-800">Contact</h3>
            <h4 className="text-xl text-gray-400 italic underline cursor-pointer">
              archisium@gmail.com
            </h4>
            <h3 className="text-3xl text-gray-800 mt-4">Based in</h3>
            <h4 className="text-xl text-gray-400">Pune, Maharashtra</h4>
            <div className="mt-4 text-2xl flex gap-4">
              <i className="ri-instagram-line"></i>
              <i className="ri-facebook-fill"></i>
              <i className="ri-twitter-x-fill"></i>
            </div>
          </div>
        </form>
        <div className="py-8 border-b-4 border-yellow-300">
          <MapEmbed />
        </div>
      </section>
      <Footer />
    </>
  );
}
