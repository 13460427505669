import React from "react";
import Nav from "../Ui/Nav";
import Footer from "../Ui/Footer";
import PageTitle from "../Ui/PageTitle";
import Card from "../Ui/Card";

const cardData = [
  {
    title: "Design",
    bg: "https://images.pexels.com/photos/29434294/pexels-photo-29434294/free-photo-of-grand-historical-staircase-in-hamburg-building.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Design Intelligence",
    bg: "https://images.pexels.com/photos/29434293/pexels-photo-29434293/free-photo-of-spiral-staircase-architectural-detail-in-hamburg.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Ecos Studio",
    bg: "https://images.pexels.com/photos/3494806/pexels-photo-3494806.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Experiential Design",
    bg: "https://images.pexels.com/photos/3778613/pexels-photo-3778613.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "IAXR",
    bg: "https://images.pexels.com/photos/2630793/pexels-photo-2630793.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Lighting Design",
    bg: "https://images.pexels.com/photos/2982185/pexels-photo-2982185.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Strategy",
    bg: "https://images.pexels.com/photos/7109155/pexels-photo-7109155.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Techni",
    bg: "https://images.pexels.com/photos/7218534/pexels-photo-7218534.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];
export default function Howwedoit() {
  return (
    <>
      <Nav />
      <PageTitle
        title="How we do it"
        bg="https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />
      <section className="px-5 lg:px-32 pb-8 text-center">
        <h2 className="text-3xl text-gray-800 pb-5">
          Our approach is built on a foundation of integrated services.
        </h2>
        <p className="text-xl text-gray-500 pb-7">
          By collaborating closely with our clients, we combine diverse
          perspectives, skills, and technologies to craft experiences that truly
          resonate with people. This seamless fusion of creativity and technical
          proficiency allows us to consistently redefine the possibilities of
          impactful design.
        </p>
        <div className="py-5 border-t-4 border-b-4 border-yellow-300 grid grid-cols-1 lg:grid-cols-4 gap-4">
          {cardData.map((data) => (
            <Card cardTitle={data.title} bg={data.bg} />
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
