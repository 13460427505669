import React from "react";

const MapEmbed = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.5473519964494!2d73.9015575!3d18.50415155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1c5df3ea95b%3A0x4a9db712fe2ef875!2sFatima%20Nagar%2C%20Wanowrie%2C%20Pune%2C%20Maharashtra%20411040!5e0!3m2!1sen!2sin!4v1732005414972!5m2!1sen!2sin"
        width="1125"
        height="350"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map of Fatima Nagar, Pune"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
