import Nav from "../Ui/Nav";
import Footer from "../Ui/Footer";
import PageTitle from "../Ui/PageTitle";
import Card from "../Ui/Card";

const cardData = [
  {
    title: "Participatory Design: Why and How?",
    bg: "https://images.pexels.com/photos/103596/pexels-photo-103596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "The Profitable Property: A Modern Transformation ",
    bg: "https://images.pexels.com/photos/2996073/pexels-photo-2996073.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Lighting the Action",
    bg: "https://images.pexels.com/photos/439227/pexels-photo-439227.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "The Customer Experience Center",
    bg: "https://images.pexels.com/photos/1648776/pexels-photo-1648776.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Power of Generosity in Real Estate",
    bg: "https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

export default function Ourblog() {
  return (
    <>
      <Nav />
      <PageTitle
        title="Our Blog"
        bg="https://images.pexels.com/photos/276724/pexels-photo-276724.jpeg"
      />
      <section className="px-5 lg:px-32">
        <ul className="flex flex-col lg:flex-row items-center justify-evenly gap-5 uppercase border-b">
          <li className="text-xl hover:underline decoration-yellow-300 decoration-4 cursor-pointer duration-300 ease-in underline-offset-8">
            Blog Articles
          </li>
          <li className="text-xl hover:underline decoration-yellow-300 decoration-4 cursor-pointer duration-300 ease-in underline-offset-8">
            Webinars
          </li>
          <li className="text-xl hover:underline decoration-yellow-300 decoration-4 cursor-pointer duration-300 ease-in underline-offset-8">
            Research
          </li>
          <li className="text-xl hover:underline decoration-yellow-300 decoration-4 cursor-pointer duration-300 ease-in underline-offset-8">
            Videos
          </li>
          <li className="text-xl hover:underline decoration-yellow-300 decoration-4 cursor-pointer duration-300 ease-in underline-offset-8">
            Updates
          </li>
        </ul>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 my-5">
          {cardData.map((data) => (
            <Card cardTitle={data.title} bg={data.bg} />
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
