import React from "react";
import logo from "../assets/logo.png";
import Footer from "../Ui/Footer";
import Nav from "../Ui/Nav";

export default function Home() {
  return (
    <>
      <Nav />
      <section className="h-screen w-full relative flex justify-center items-center ">
        <video
          className="absolute top-0 left-0 h-screen w-full object-cover z-[-1]"
          src="https://interiorarchitects.com/wp-content/uploads/2022/04/ia_home_page-720p.mp4"
          autoPlay
          loop
          muted
        ></video>
        <div className="relative text-center text-yellow-50">
          <img src={logo} alt="Archisium Logo" className="w-[450px] mt-24" />
          <p className="text-6xl mt-24">
            <i className="ri-arrow-down-s-line"></i>
          </p>
        </div>
      </section>
      <section
        className={`h-screen w-full bg-[url("https://images.unsplash.com/photo-1586023492125-27b2c045efd7?q=80&w=1916&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")] bg-cover bg-center flex flex-col justify-center items-center gap-4`}
      >
        <h1 className="text-4xl md:text-6xl bold text-white tracking-wide">
          Hello. We Are TA.
        </h1>
        <button className="p-4 bg-yellow-300 text-white hover:bg-yellow-200 duration-300 ease-in uppercase">
          Who we are
        </button>
      </section>
      <section
        className={`h-screen w-full bg-[url("https://img.freepik.com/free-photo/armchair-green-living-room-with-copy-space_43614-910.jpg?t=st=1731666091~exp=1731669691~hmac=b536d11d74508dfcfd75255d245dc6c2631aef32af5532a324f307f394fac83f&w=996")] bg-cover bg-center flex flex-col justify-center items-center gap-4`}
      >
        <h1 className="text-4xl md:text-6xl bold text-white tracking-wide">
          We Know Environment.
        </h1>
        <button className="p-4 bg-yellow-300 text-white hover:bg-yellow-200 duration-300 ease-in uppercase">
          What We Do
        </button>
      </section>
      <section
        className={`h-screen w-full bg-[url("https://images.pexels.com/photos/161758/governor-s-mansion-montgomery-alabama-grand-staircase-161758.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")] bg-cover bg-center flex flex-col justify-center items-center gap-4`}
      >
        <h1 className="text-4xl md:text-6xl bold text-white tracking-wide">
          Read Our Blog.
        </h1>
        <button className="p-4 bg-yellow-300 text-white hover:bg-yellow-200 duration-300 ease-in uppercase">
          What We're saying
        </button>
      </section>
      <Footer />
    </>
  );
}
