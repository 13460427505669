import React from "react";

export default function Footer() {
  return (
    <footer className="grid md:grid-cols-4 w-full py-8 px-5 lg:px-32 gap-8 md:gap-0 text-gray-700">
      <ul className="">
        <li className="text-[16px] uppercase text-yellow-300 my-2">
          Useful links
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          Who are we
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          What we do
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          How we do it
        </li>
      </ul>
      <ul className="">
        <li className="text-[16px] uppercase text-yellow-300 my-2">
          Thought Leadership
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          Blog
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          Webinars
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          Research
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          Press
        </li>
      </ul>
      <ul className="">
        <li className="text-[16px] uppercase text-yellow-300 my-2">Contact</li>
        <li className="text-[20px]">
          <i className="ri-map-pin-2-fill"></i>
          <span className="text-[15px]"> Find TA</span>
        </li>
        <li className="text-[20px]">
          <i className="ri-phone-fill"></i>
          <span className="text-[15px] text-yellow-300 hover:text-black cursor-pointer">
            (+91 XXXXX XXXXX)
          </span>
        </li>
        <li className="text-[20px]">
          <i className="ri-mail-fill"></i>
          <span className="text-[15px] text-yellow-300 hover:text-black cursor-pointer">
            archisium@gmail.com
          </span>
        </li>
      </ul>
      <ul className="">
        <li className="text-[16px] uppercase text-yellow-300 my-2">Social</li>
        <li className="text-[20px]">
          <span className="mr-2 cursor-pointer hover:text-gray-500">
            <i className="ri-linkedin-box-fill"></i>
          </span>
          <span className="mr-2 cursor-pointer hover:text-gray-500">
            <i className="ri-instagram-fill"></i>
          </span>
          <span className="mr-2 cursor-pointer hover:text-gray-500">
            <i className="ri-facebook-box-fill"></i>
          </span>
          <span className="mr-2 cursor-pointer hover:text-gray-500">
            <i className="ri-youtube-fill"></i>
          </span>
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          SUBSCRIBE
        </li>
        <li className="hover:text-yellow-300 cursor-pointer text-[15px]">
          POLICY
        </li>
      </ul>
    </footer>
  );
}
