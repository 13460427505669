import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import "remixicon/fonts/remixicon.css";
import Whatwedo from "./pages/Whatwedo";
import Ourblog from "./pages/Ourblog";
import Contact from "./pages/Contact";
import Howwedoit from "./pages/Howwedoit";
import About from "./pages/About";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/whatwedo" element={<Whatwedo />} />
        <Route path="/howwedoit" element={<Howwedoit />} />
        <Route path="/ourblog" element={<Ourblog />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
