import React from "react";

export default function Card({ cardTitle, bg }) {
  return (
    <div
      className="relative h-[300px] cursor-pointer flex justify-center items-center rounded-md  text-center text-white text-base hover:text-lg duration-300 ease-in-out bg-cover"
      style={bg ? { backgroundImage: `url(${bg})` } : {}}
    >
      <div className=" absolute inset-0 bg-black opacity-50 hover:opacity-30 duration-300 ease-in-out rounded-md"></div>
      <h2 className="z-10 pointer-events-none">{cardTitle}</h2>
    </div>
  );
}
