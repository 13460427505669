import React from "react";
import Nav from "../Ui/Nav";
import Footer from "../Ui/Footer";
import PageTitle from "../Ui/PageTitle";
import Card from "../Ui/Card";

const cardData = [
  {
    title: "Designing Buildings",
    bg: "https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Planning and Zoning",
    bg: "https://images.pexels.com/photos/28293765/pexels-photo-28293765/free-photo-of-a-small-house-with-a-large-glass-door.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Renovations",
    bg: "https://images.pexels.com/photos/1648776/pexels-photo-1648776.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Land Surveying",
    bg: "https://images.pexels.com/photos/2996073/pexels-photo-2996073.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Infrastructure Planning",
    bg: "https://images.pexels.com/photos/103596/pexels-photo-103596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    title: "Urban Development",
    bg: "https://images.pexels.com/photos/29407145/pexels-photo-29407145/free-photo-of-scenic-urban-skyline-at-water-s-edge.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Custom Designs",
    bg: "https://images.pexels.com/photos/439227/pexels-photo-439227.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Space Planning",
    bg: "https://images.pexels.com/photos/259580/pexels-photo-259580.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Material Selection",
    bg: "https://images.pexels.com/photos/159045/the-interior-of-the-repair-interior-design-159045.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "3D Models",
    bg: "https://images.pexels.com/photos/7578884/pexels-photo-7578884.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Concept Demonstration",
    bg: "https://images.pexels.com/photos/6990473/pexels-photo-6990473.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    title: "Material Experimentation",
    bg: "https://images.pexels.com/photos/9618127/pexels-photo-9618127.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

export default function Whatwedo() {
  return (
    <>
      <Nav />
      <PageTitle
        title="What we do"
        bg="https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      />
      <section className="px-5 lg:px-32">
        <ul className="flex flex-col lg:flex-row justify-center items-center gap-10 border-b-2">
          <li className="uppercase text-[16px] cursor-pointer hover:underline hover:underline-offset-8 decoration-yellow-300 hover:decoration-4 duration-300 ease-in-out">
            <span className="text-[18px]">
              <i className="ri-arrow-right-s-fill"></i>
            </span>{" "}
            Architects
          </li>
          <li className="uppercase text-[16px] cursor-pointer hover:underline hover:underline-offset-8 decoration-yellow-300 hover:decoration-4 duration-300 ease-in-out">
            <span className="text-[18px]">
              <i className="ri-arrow-right-s-fill"></i>
            </span>{" "}
            Surveyors
          </li>
          <li className="uppercase text-[16px] cursor-pointer hover:underline hover:underline-offset-8 decoration-yellow-300 hover:decoration-4 duration-300 ease-in-out">
            <span className="text-[18px]">
              <i className="ri-arrow-right-s-fill"></i>
            </span>{" "}
            Townplanners
          </li>
          <li className="uppercase text-[16px] cursor-pointer hover:underline hover:underline-offset-8 decoration-yellow-300 hover:decoration-4 duration-300 ease-in-out">
            <span className="text-[18px]">
              <i className="ri-arrow-right-s-fill"></i>
            </span>{" "}
            Interrior Designers
          </li>
          <li className="uppercase text-[16px] cursor-pointer hover:underline hover:underline-offset-8 decoration-yellow-300 hover:decoration-4 duration-300 ease-in-out">
            <span className="text-[18px]">
              <i className="ri-arrow-right-s-fill"></i>
            </span>{" "}
            Modal Makers
          </li>
        </ul>

        <div className="my-6 grid grid-cols-1 lg:grid-cols-4 gap-3">
          {cardData.map((data) => (
            <Card cardTitle={data.title} bg={data.bg} />
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
